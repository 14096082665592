/** @format */

import {ComponentMultiStyleConfig} from '@chakra-ui/react';
import {colors} from '../../colors';

export const homeHeroBanner: ComponentMultiStyleConfig = {
	parts: ['box', 'headingContainer', 'textContainer'],
	baseStyle: {
		//
		box: {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		},
		headingContainer: {
			margin: {base: '0 0.5rem 1rem 0', md: '0 1.125rem 0.625rem 0.25rem'},
		},
		textContainer: {
			m: {base: '1rem 0 1.5rem', md: '0.625rem 0 1.5rem'},
		},
		card: {
			p: {
				base: '1.063rem 0.5rem 1rem 1rem',
				md: '3.375rem 2.5rem 3rem 2.438rem',
			},
			border: '2px',
			borderColor: colors.moss[200],
			borderRadius: '5px',
			backgroundColor: colors.arctic,
			boxShadow: '0 0 15px 0 rgba(8, 146, 95, 0.07)',
			position: 'relative',
			w: {base: 'auto', md: '34.688rem'},
			mt: {base: 'auto', md: '0'},
			mb: {base: '16px', md: '0'},
			minW: '160px',
		},
	},
};
