/**
 * eslint-disable @typescript-eslint/no-empty-function
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/naming-convention: 1, @typescript-eslint/no-empty-function: 1, @typescript-eslint/no-floating-promises: 1
 *
 * @format
 */

import axios from 'axios';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';

type User = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/naming-convention
type IAuthContext = {
	signUp: (email: string, password: string) => Promise<User>;
	login: (email: string, password: string) => Promise<User>;
	logout: () => Promise<void>;
	sendPasswordReset: (email: string) => Promise<void>;
	confirmPasswordResetCode: (code: string, password: string) => Promise<void>;
	sendVerificationEmailToUser: (user: User | undefined) => Promise<void>;
	user: User | undefined;
	loading: boolean;
};

const AuthContext = React.createContext({} as IAuthContext);

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({
	children,
}: PropsWithChildren<Record<string, unknown>>) {
	const [user, setUser] = useState<User | undefined>(null);
	const [loading, setLoading] = useState(true);

	const signUp: IAuthContext['signUp'] = async (
		email: string,
		password: string,
	) => ({});

	const sendVerificationEmailToUser: IAuthContext['sendVerificationEmailToUser']
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		= async (userObj: User | undefined) => {};

	const login: IAuthContext['login'] = async (
		email: string,
		password: string,
	) => ({});
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const logout: IAuthContext['logout'] = async () => {};

	const sendPasswordReset: IAuthContext['sendPasswordReset'] = async (
		email: string,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
	) => {};

	const confirmPasswordResetCode: IAuthContext['confirmPasswordResetCode']
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		= async (code: string, password: string) => {};

	useEffect(() => {
		// const getUser = async () => {
		// 	const response = await axios.get('/api/member/auth/user');
		// 	setUser(response.data);
		// 	setLoading(false);
		// };
		// getUser().catch(console.error);
	}, []);

	const value = {
		user,
		signUp,
		logout,
		login,
		sendPasswordReset,
		confirmPasswordResetCode,
		sendVerificationEmailToUser,
		loading,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
