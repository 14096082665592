/**
 * /* eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

// 1. Import the extendTheme function
import {extendTheme} from '@chakra-ui/react';
import {Button} from './button';
import {Card} from './card';
import {colors} from './colors';
import {dividerTheme} from './divider';
import {fonts} from './fonts';
import {FormError} from './formError';
import {Input} from './input';
import {layerStyles} from './layerStyles';
import {Link} from './link';
import {boardMembersList} from './patterns/boardMembersList/boardMembersList';
import {featuredQuote} from './patterns/featuredQuote/featuredQuote';
import {homeHeroBanner} from './patterns/homeHeroBanner/homeHeroBanner';
import {imageAndText} from './patterns/imageAndText/imageAndText';
import {imageAndTextFullWidth} from './patterns/imageAndTextFullWidth/imageAndTextFullWidth';
import {textAndBullets} from './patterns/textAndBullets/textAndBullets';
import {customSteps} from './steps';
import {Textarea} from './textarea';
import {textStyles} from './textStyles';
import {tooltip} from './tooltip';
import {fontSizes} from './typography';

// 2. Extend the theme to include custom colors, fonts, etc
export const theme = extendTheme({
	styles: {
		global(props: any) {
			return {
				'.WYSIWYG': {
					a: {
						cursor: 'auto',
						color: 'blue',
						textDecoration: 'underline',
						_hover: {
							textDecoration: 'underline',
						},
					},
					p: {
						marginTop: '1em',
						marginBottom: '1em',
					},
				},

				'.WYSIWYG--default': {
					...textStyles.paragraph1LeftDark,
					'h1:not(:first-of-type), * > h1': {
						display: 'none',
					},
					h1: {
						...textStyles.display2LeftDark,
					},
					h2: {
						...textStyles.display2LeftDark,
						marginBottom: '2rem',
					},
					h3: {
						...textStyles.display3LeftDark,
						marginBottom: '2rem',
					},
					h4: {
						...textStyles.display4LeftDark,
						marginBottom: '2rem',
					},
					h5: {
						...textStyles.display5LeftDark,
						marginBottom: '2rem',
					},
					h6: {
						...textStyles.display6LeftDark,
						marginBottom: '2rem',
					},
					p: {
						...textStyles.paragraph1LeftItalicDark,
						marginBottom: '2rem',
					},
					strong: {
						fontWeight: 600,
					},
					a: {
						cursor: 'pointer',
						textDecoration: 'underline',
						color: colors.slate[700],
						wordBreak: 'break-word',
						_hover: {
							color: colors.aquaGreen[500],
						},
						_active: {
							color: colors.aquaGreen[900],
						},
						_focus: {
							color: colors.aquaGreen[700],
						},
						_disabled: {
							color: colors.lime[400],
						},
					},
					'ul, ol': {
						...textStyles.paragraph1LeftItalicDark,
						listStylePosition: 'outside',
						ml: '1rem',
						marginBottom: '2rem',
					},
					ul: {
						listStyle: 'none',
					},
					'ul li::before': {
						content: '"•"',
						color: colors.aquaGreen[700],
						display: 'inlineBlock',
						width: '1em',
						marginRight: '.5em',
						lineHeight: '1.6rem',
					},
					b: {
						...textStyles.displayBoldWysiwyg,
						color: colors.leaf[900],
					},
					blockquote: {
						...textStyles.display3LeftDark,
						marginBottom: '2rem',
					},
					hr: {
						borderColor: colors.aquaGreen,
						margin: '2rem 0',
					},
					small: {
						...textStyles.paragraph2LeftItalicDark,
						marginBottom: '2rem',
					},
				},

				'html, body': {
					select: {
						borderRadius: 'md',
						borderWidth: '1px',
					},
					'.grecaptcha-badge': {
						zIndex: '999',
					},
					'.form-message': {
						'&.error': {
							color: 'red',
						},
						'&.success': {
							color: 'green',
						},
					},
					// a: {
					// 	cursor: 'auto',
					// 	color: 'blue',
					// 	textDecoration: 'underline',
					// 	_hover: {
					// 		textDecoration: 'underline',
					// 	},
					// },
				},
			};
		},
	},
	components: {
		Tooltip: tooltip,
		Steps: customSteps,
		/* eslint @typescript-eslint/naming-convention: 1 */
		Menu: {
			sizes: {
				sm: {
					w: '30em',
				},
				md: {
					w: '48em',
				},
				lg: {
					w: '62em',
				},
				xl: {
					w: '80em',
				},
				'2xl': {
					w: '96em',
				},
			},
		},
		Heading: {
			sizes: null,
		},
		Button,
		Link,
		FormLabel: {
			...textStyles.displayFormLabel,
		},
		Input,
		Select: Input,
		FormError,
		Textarea,
		Card,
		Divider: dividerTheme,
		/*
			patterns
		*/
		homeHeroBanner,
		featuredQuote,
		imageAndText,
		textAndBullets,
		imageAndTextFullWidth,
		boardMembersList,
	},
	fonts,
	fontSizes,
	colors,
	space: {
		spacer: '64px',
	},
	sizes: {
		maxWidth: '1200px',
	},
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	textStyles,
	layerStyles,
});

export default theme;
