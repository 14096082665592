
/** @format */

import {StepsStyleConfig} from 'chakra-ui-steps';
import {fonts} from './fonts';
import {colors} from './colors';
const activeColor = colors.sage[800];

export const customSteps = {
	...StepsStyleConfig,
	baseStyle(props) {
		return {
			...StepsStyleConfig.baseStyle(props),
			connector: {
				...StepsStyleConfig.baseStyle(props).connector,
				_highlighted: {
					borderColor: activeColor,
				},
			},
			label: {
				...StepsStyleConfig.baseStyle(props).label,
				fontFamily: fonts.base,
				color: colors.leaf[900],
			},
			steps: {
				...StepsStyleConfig.baseStyle(props).step,
				justifyContent: 'flex-start',
			},
			stepIconContainer: {
				...StepsStyleConfig.baseStyle(props).stepIconContainer,
				bg: 'white',
				_activeStep: {
					bg: 'white',
					borderColor: activeColor,
				},
				_highlighted: {
					bg: activeColor,
					borderColor: activeColor,
				},
			},
		};
	},
};
