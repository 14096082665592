/* eslint-disable @typescript-eslint/naming-convention */
import {Input} from './input';

export const Textarea = {
	baseStyle: {
		paddingY: '12px',
		minHeight: '160px',
		lineHeight: 'short',
		verticalAlign: 'top',
	},

	sizes: {
		custom: {
			...Input.sizes.custom.field,
		},
	},

	variants: {
		bulb: {
			...Input.variants.bulb.field,
		},
	},

	defaultProps: {
		...Input.defaultProps,
	},
};
