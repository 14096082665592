import {
	defineStyle, defineStyleConfig,
} from '@chakra-ui/styled-system';
import {colors} from './colors';

const primary = defineStyle({
	alignSelf: 'center',
	maxW: '90%',
	borderColor: colors.slate[400],
	borderWidth: '2px',
	borderStyle: 'solid',
});

const secondary = defineStyle({
	alignSelf: 'center',
	w: '90%',
	borderColor: colors.slate[400],
	borderWidth: '2px',
	borderStyle: 'solid',
});

export const dividerTheme = defineStyleConfig({
	variants: {
		primary,
		secondary,
	},
});
