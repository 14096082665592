/** @format */

import {ComponentMultiStyleConfig} from '@chakra-ui/react';
import {colors} from '../../colors';

export const featuredQuote: ComponentMultiStyleConfig = {
	parts: ['box', 'quote', 'quoteInfo'],

	baseStyle: {
		box: {
			px: {base: '1rem', md: '10rem'},
			py: {base: '1rem', md: '3rem'},
			bgColor: colors.tweed[400],
		},
		quoteBox: {
			flex: 1,
		},
	},
};
