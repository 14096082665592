/** @format */

import {ComponentMultiStyleConfig} from '@chakra-ui/react';

export const boardMembersList: ComponentMultiStyleConfig = {
	parts: [
		'textContainer',
		'headingTextContainer',
		'bodyTextContainer',
		'bulletsContainer',
	],
	baseStyle: {
		textContainer: {
			m: {base: '', md: '1rem 1rem 1rem 1rem'},
			flex: '1',
		},
		headingTextContainer: {
			m: {base: '1rem 1rem 1rem 1rem', md: '3rem 0 2rem'},
		},
		bodyTextContainer: {
			m: {base: '1rem 1rem 1rem 1rem', md: '3rem 0 2rem'},
		},
		bulletsContainer: {
			w: {base: '21.438rem', md: '66%'},
			m: {base: '1rem 1rem 1rem 1rem', md: '1rem 0'},
			flex: '1',
		},
	},
};
