/**
 * /* eslint no-mixed-spaces-and-tabs: 1
 *
 * @format
 */

import {fonts} from './fonts';
import {fontSizes} from './typography';
import {colors} from './colors';
import {ChakraProps} from '@chakra-ui/react';

export const textStyles: Record<string, ChakraProps> = {
	display5LeftLightSemi: {
		fontFamily: fonts.display,
		fontSize: fontSizes['4xl'],
		fontWeight: 'semibold',
		fontStyle: 'normal',
		letterSpacing: -0.32,
		color: colors.arctic,
	},
	display5LeftDarkSemi: {
		fontFamily: fonts.display,
		fontSize: fontSizes['3xl'],
		fontWeight: 'semibold',
		fontStyle: 'normal',
		letterSpacing: -0.32,
		color: colors.leaf[900],
	},
	display5LeftDark: {
		fontFamily: fonts.display,
		fontSize: fontSizes['3xl'],
		fontWeight: 'normal',
		fontStyle: 'normal',
		letterSpacing: -0.32,
		color: colors.leaf[900],
	},
	display5LeftLight: {
		fontFamily: fonts.display,
		fontSize: fontSizes['3xl'],
		fontWeight: 'normal',
		fontStyle: 'normal',
		letterSpacing: -0.32,
		color: colors.arctic,
	},
	paragraph1LeftItalicDark: {
		fontFamily: fonts.base,
		fontSize: '20px',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.leaf[900],
	},
	paragraph2LefGrey: {
		fontFamily: fonts.base,
		fontSize: fontSizes.md,
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.slate[600],
	},
	paragraph2LeftItalicDark: {
		fontFamily: fonts.base,
		fontSize: fontSizes.md,
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.leaf[900],
	},
	paragraph2LeftDarkSlate: {
		fontFamily: fonts.base,
		fontSize: fontSizes.md,
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.slate[700],
	},
	buttonCenterDark: {
		fontFamily: 'Outfit',
		fontSize: fontSizes.lg,
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		textAlign: 'center',
		color: '#0a1627',
	},
	display1LeftLight: {
		fontFamily: fonts.display,
		fontSize: '4rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.13',
		letterSpacing: '-0.64px',
		color: colors.arctic,
	},
	display1LeftDark: {
		fontFamily: fonts.display,
		fontSize: '4rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.13',
		letterSpacing: '-0.64px',
		color: colors.leaf[900],
	},
	display2LeftLight: {
		fontFamily: fonts.display,
		fontSize: '3.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.14',
		letterSpacing: '-0.56px',
		color: colors.arctic,
	},
	display2LeftDark: {
		fontFamily: fonts.display,
		fontSize: '3.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.14',
		letterSpacing: '-0.56px',
		color: colors.leaf[900],
	},
	display3LeftLight: {
		fontFamily: fonts.display,
		fontSize: '3rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.17',
		letterSpacing: '-0.48px',
		color: colors.arctic,
	},
	display3LeftDark: {
		fontFamily: fonts.display,
		fontSize: '3rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.17',
		letterSpacing: '-0.48px',
		color: colors.leaf[900],
	},
	heading1LeftDark: {
		fontFamily: fonts.heading,
		fontSize: fontSizes['5xl'],
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '3.5rem',
		letterSpacing: -0.32,
		color: colors.leaf[900],
	},
	heading6LeftLeaf: {
		fontFamily: fonts.heading,
		fontSize: fontSizes['2xl'],
		fontWeight: 500,
		lineHeight: '1.333333333em',
		letterSpacing: -0.24,
		color: colors.leaf[400],
	},
	display8LeftAbyss: {
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '-0.16px',
		color: colors.leaf[900],
		fontFamily: fonts.heading,
		fontWeight: 600,
	},
	displayLabel: {
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '-0.16px',
		color: colors.custom1[100],
		fontFamily: fonts.heading,
		fontWeight: 'normal',
	},
	display8LeftArctic: {
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '-0.16px',
		color: colors.arctic,
		fontFamily: fonts.heading,
		fontWeight: 600,
	},
	tagsBadgesBadgesWhite: {
		fontFamily: fonts.tag,
		fontSize: fontSizes.xl,
		fontWeight: 700,
		lineHeight: '1.6em',
		letterSpacing: -0.2,
		color: colors.arctic,
	},
	display4LeftLight: {
		fontFamily: fonts.display,
		fontSize: '2.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.2',
		letterSpacing: '-0.4px',
		color: colors.arctic,
	},
	display4LeftDark: {
		fontFamily: fonts.display,
		fontSize: fontSizes['4xl'],
		lineHeight: '3rem',
		letterSpacing: '-0.4px',
		color: colors.leaf[900],
	},
	display6LeftDarkGreen: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.24px',
		color: colors.aquaGreen[700],
	},
	display6LeftLight: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.24px',
		color: colors.arctic,
	},
	display6LeftDark2: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.24px',
		color: colors.custom1[100],
	},
	display6LeftDark: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.24px',
		color: colors.leaf[900],
	},
	display6LeftDarkSemi: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'semibold',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.24px',
		color: colors.leaf[900],
	},
	tagsBadgesBadgesLight: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 500,
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		textAlign: 'center',
		color: colors.arctic,
	},
	tagsBadgesBadgesDark: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 500,
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		textAlign: 'center',
		color: colors.leaf[900],
	},
	tagsDatesLight: {
		fontFamily: fonts.body,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		textAlign: 'center',
		color: colors.aquaGreen[600],
	},
	paragraph1LeftDarkGreen: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.aquaGreen[600],
	},
	paragraph1LeftDark: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.custom1[100],
	},
	paragraph1LeftDark2: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.custom1[100],
	},
	paragraph1LeftItalicLight: {
		fontFamily: fonts.base,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.arctic,
	},
	listDarkGreen: {
		fontFamily: fonts.display,
		fontSize: '1.5rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.2px',
		color: colors.aquaGreen[700],
	},

	display7LeftLight: {
		fontFamily: fonts.display,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.6',
		letterSpacing: '-0.2px',
		color: colors.arctic,
	},
	display7LeftDark: {
		fontFamily: fonts.display,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.2',
		letterSpacing: '-0.2px',
		color: colors.leaf[900],
	},
	display7LeftDarkSemi: {
		fontFamily: fonts.display,
		fontSize: '1.25rem',
		fontWeight: 'semibold',
		fontStyle: 'normal',
		lineHeight: '1.2',
		letterSpacing: -0.2,
		color: colors.leaf[900],
	},
	display7LeftGreenSemi: {
		fontFamily: fonts.display,
		fontSize: '1.25rem',
		fontWeight: 'semibold',
		fontStyle: 'normal',
		lineHeight: '1.2',
		letterSpacing: -0.2,
		color: colors.sage[600],
	},
	display7LeftDarkGreen: {
		fontFamily: fonts.display,
		fontSize: '1.25rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.2',
		letterSpacing: '-0.2px',
		color: colors.aquaGreen[700],
	},
	buttonLeftGreen: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		color: colors.aquaGreen[500],
	},
	buttonCentreAquaGreen: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		textAlign: 'center',
		color: colors.aquaGreen[500],
		_hover: {
			color: colors.aquaGreen[300],
		},
		_active: {
			color: colors.aquaGreen[700],
		},
	},
	buttonLeftLight: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		color: colors.arctic,
	},
	buttonLeftDark2: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		color: colors.custom1[100],
	},
	buttonLeftDark: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		color: colors.leaf[900],
	},
	buttonCentreLight: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		textAlign: 'center',
		color: colors.arctic,
	},
	buttonCentreDark: {
		fontFamily: fonts.base,
		fontSize: '1.125rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.33',
		letterSpacing: '-0.18px',
		textAlign: 'center',
		color: colors.custom1[100],
	},
	paragraph2LeftLight: {
		fontFamily: fonts.base,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.arctic,
	},
	paragraph2LeftLightGreen: {
		fontFamily: fonts.base,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.aquaGreen[600],
	},
	paragraph2LeftDark: {
		fontFamily: fonts.base,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.leaf[900],
	},
	paragraph2LeftGrey: {
		fontFamily: 'Outfit',
		fontSize: 16,
		fontWeight: 'normal',
		fontStyle: 'normal',
		letterSpacing: -0.16,
		color: colors.slate[600],
	},
	paragraph2LeftItalicDark2: {
		fontFamily: fonts.base,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.custom1[100],
	},

	display8LeftLight: {
		fontFamily: fonts.display,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.arctic,
	},
	display8LeftDark: {
		fontFamily: fonts.display,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: colors.leaf[900],
	},
	paragraph3LeftLight: {
		fontFamily: fonts.base,
		fontSize: '0.875rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.71',
		letterSpacing: '-0.14px',
		color: colors.arctic,
	},
	paragraph3LeftItalicDark: {
		fontFamily: fonts.base,
		fontSize: '0.875rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.71',
		letterSpacing: '-0.14px',
		color: colors.leaf[900],
	},
	display9LeftLight: {
		fontFamily: fonts.display,
		fontSize: '0.875rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.43',
		letterSpacing: '-0.14px',
		color: colors.arctic,
	},
	display9LeftDark: {
		fontFamily: fonts.display,
		fontSize: '0.875rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.43',
		letterSpacing: '-0.14px',
		color: colors.leaf[900],
	},
	displayCustom1LeftDark: {
		fontFamily: fonts.base,
		fontSize: '1rem',
		fontWeight: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.5',
		letterSpacing: '-0.16px',
		color: '#718d97', //
	},
	paragraph3LeftError: {
		color: 'red',
	},
	displayFormLabel: {
		fontFamily: fonts.display,
		fontSize: fontSizes.md,
		lineHeight: '24px',
		letterSpacing: '-0.16px',
		color: colors.custom1[100],
	},
	displayLinkWysiwyg: {
		fontFamily: fonts.base,
		fontSize: fontSizes.xl,
		lineHeight: '32px',
		letterSpacing: '-0.2px',
	},
	displayBoldWysiwyg: {
		fontFamily: fonts.base,
		lineHeight: '32px',
		letterSpacing: '-0.2px',
	},
};
