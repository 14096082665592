import {ComponentMultiStyleConfig} from '@chakra-ui/react';

export const imageAndText: ComponentMultiStyleConfig = {
	parts: ['stack', 'textContainer', 'image', 'headingTextContainer', 'bodyTextContainer'],
	baseStyle: {
		stack: {
			pt: '1rem',
			pb: '1rem',
		},
		textContainer: {
			flex: '1',
		},
		image: {
			w: {base: '', md: '34.688rem'},
			h: {base: 'auto', md: '24.938rem'},
			m: 'auto',
		},
		headingTextContainer: {
			my: {base: '1rem', md: '2rem'},
		},
		bodyTextContainer: {
			my: {base: '1rem', md: '2rem'},
		},
	},
};
