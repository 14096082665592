/**
 * eslint @typescript-eslint/naming-convention: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/naming-convention: 1
 *
 * @format
 */

import {colors} from './colors';
import {textStyles} from './textStyles';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Input = {
	// Style object for base or default style
	baseStyle: {
		field: {
			...textStyles.paragraph2LeftItalicDark,
		},
		addon: {
			...textStyles.paragraph2LeftItalicDark,
		},
	},
	// Styles for different sizes ("sm", "md", "lg")
	sizes: {
		custom: {
			field: {fontSize: 'md', px: 4, h: 12, borderRadius: 'base'},
			addon: {fontSize: 'md', px: 4, h: 12, borderRadius: 'base'},
		},
	},
	// Styles for different visual variants ("outline", "solid")
	variants: {
		bulb: {
			field: {
				border: '2px solid',
				borderColor: 'obsidian.100',
				background: 'white',
				_hover: {
					borderColor: 'obsidian.200',
				},
				_readOnly: {
					boxShadow: 'none !important',
					userSelect: 'all',
				},
				_disabled: {
					opacity: 0.4,
					cursor: 'not-allowed',
					backgroundColor: 'obsidian.50',
				},
				_invalid: {
					borderColor: 'coral',
					boxShadow: 'none',
				},
				_focus: {
					// zIndex: 1,
					borderColor: colors.lime[500],
					boxShadow: '0 0 0 2px rgba(141, 197, 63, 0.05), 0 0 4px 0 #8dc53f',
				},
			},
			addon: {
				border: '2px solid',
				borderColor: 'obsidian.100',
				bg: 'obsidian.50',
			},
		},
	},
	// Default values for `size` and `variant`
	defaultProps: {
		size: 'custom',
		variant: 'bulb',
	},
};
