/** @format */

import {createMultiStyleConfigHelpers} from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
	'backgroundImageContainer',
	'backgroundImage',
	'textBox',
]);

export const imageAndTextFullWidth = helpers.defineMultiStyleConfig({
	baseStyle: {
		backgroundImageContainer: {
			h: '34rem',
			position: 'relative',
			py: {base: '2rem', md: '6rem'},
		},
		backgroundImage: {
			maxWidth: '100%',
			objectFit: 'cover',
			opacity: '0.22',
		},
		textBox: {
			position: 'relative',
			maxW: '40.75rem',
		},
	},
});
