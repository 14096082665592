/* eslint @typescript-eslint/naming-convention: 1 */
/**
 * /* eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

export const colors = {
	white: '#fff',
	black: '#000',
	arctic: '#ffffff',
	coral: '#ff4d70',
	patternBaseBg: '#d8d8d8',
	arcticScale: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#fff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#fff', // Labelled Abyss in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#fff',
	},
	gneiss: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#747979',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#3a5257',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#546668',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#253d46',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#162d3a',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#a9aeae',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#d1d6d5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#f6f8f7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#edf1f0',
	},
	abyss: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#E4E6FF',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#b3b5ff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#8183fd',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#5053fc',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#2423fb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#110ce2',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#0a08b0',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#04057e',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#00024c', // Labelled Abyss in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#00011d',
	},
	ultramarine: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#e4eaff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#b4c1ff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#8498fb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#546ff8',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#2645f5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#112ddc',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#0a22ab',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#04187b', // Labelled Ultramarine in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#000f4c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#00051e',
	},
	azure: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#e4ecff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#b4c5ff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#849efb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#5478f8',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#2651f5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#0e38db',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#072bab', // Labelled Azure in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#021f7b',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#00134c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#00061e',
	},
	santorini: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#ddf3ff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#b0daff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#80bfff',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#4ea5fb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#208cf9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#0672df', // Labelled Santorini in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#0059af',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#003f7e',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#00264e',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#000e20',
	},
	capri: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#dbfefd',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#b6f6f1',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#8dede6',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#64e5dd',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#3bddd3',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#23CCC1', // Labelled Capri in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#129890',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#036e67',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#00423f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#001817',
	},
	obsidian: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#eff4f6',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#d5dddf',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#b8c6cb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#9ab1b7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#7d9ba4',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#64818a',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#4f646b',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#39474c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#222b2d',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#090f10', // Labelled Obsidian in designs
	},
	shadow: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#ebf5f5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#d7dadb',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#bfc0c1',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#a6a8a8',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#8c8f8f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#737576',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#595b5c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#3f4142',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#242728', // Labelled Shadow in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#040f0f',
	},
	granite: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#fceff2',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#ddd7d9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#c1bfbf',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#a6a6a6', // Labelled Granite in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#8c8c8c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#737373',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#595959',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#413f40',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#292526',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#16090d',
	},
	lunar: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		50: '#f2f2f2', // Labelled Lunar in designs
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#d9d9d9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#bfbfbf',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#a6a6a6',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#8c8c8c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#737373',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#595959',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#404040',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#262626',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#0d0d0d',
	},
	leaf: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#C7F7CD',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#93EFA7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#58D17E',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#2DA45E',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#046837',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#025937',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#024A35',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#013C31',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#00312D',
	},
	lime: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#F3FCD9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#E5F9B5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#CDED8C',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#B2DC6B',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#8dc53f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#70a92e',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#568d1f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#3e7214',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#2e5e0c',
	},
	aquaGreen: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#ccfad2',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#9af6b0',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#65e58f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#3dcc7b',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#0caa60',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#08925f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#067a5b',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#036252',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#02514c',
	},
	sage: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#f7fcea',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#eef9d5',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#dcedba',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#c5dca1',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#a7c57f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#85a95c',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#658d40',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#497228',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#345e18',
	},
	moss: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#f0faee',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#dff5de',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#c2e1c3',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#a2c4a7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#799e82',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#588768',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#3c7153',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#265b42',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#174b38',
	},
	tweed: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#fcfbf7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#f9f7f0',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#efeae2',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#dfd9d0',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#cbc3b9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#ae9b87',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#92755d',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#75533b',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#613A23',
	},
	slate: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#fbfbfc',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		200: '#f7f8f9',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		300: '#ececee',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		400: '#dadbdd',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		500: '#c4c5c7',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		600: '#8f97ab',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		700: '#626e8f',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		800: '#3e4a73',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		900: '#25315f',
	},
	custom1: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		100: '#0a1627',
	},
};
