import {colors} from './colors';

export const layerStyles = {
	modal: {
		borderRadius: 4,
		backgroundColor: colors.arctic,
		boxShadow: '0 1px 4px -1px ' + colors.obsidian[100],
	},
	card: {
		borderRadius: 4,
		border: 'solid 2px ' + colors.obsidian[50],
		backgroundColor: colors.arctic,
	},
};
