/**
 * eslint-disable @typescript-eslint/naming-convention
 *
 * @format
 */

import {colors} from './colors';
import {fonts} from './fonts';
import {textStyles} from './textStyles';

export const tooltip = {
	baseStyle: {
		...textStyles.paragraph3LeftItalicDark,
		backgroundColor: colors.slate[500],
		padding: '1rem',
		borderRadius: '4px',
		border: `solid 1px ${colors.slate[500]}`,
	},
};
