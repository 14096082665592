/**
 * eslint @typescript-eslint/naming-convention: 1
 *
 * @format
 */

/**
 * /* eslint @typescript-eslint/naming-convention: 1
 *
 * @format
 */

import {colors} from './colors';
import {fonts} from './fonts';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Button = {
	// 1. Update the base styles
	baseStyle: {
		h: '3rem',
		fontFamily: fonts.base,
		p: '0.75rem',
		fontWeight: 'normal',
		color: 'abyss.800',
		borderRadius: '4px',
		textAlign: 'center',
		justifyContent: 'center',
		_disabled: {
			// color: 'granite.300',
		},
	},
	sizes: {
		md: {
			h: '3rem',
			p: '0.5rem',
		},
		fullWidth: {
			w: 'full',
		},
		hero: {
			w: '15rem',
		},
	},
	defaultProps: {
		variant: 'primary',
		size: 'md',
	},
	// 3.Add visual variants
	variants: {
		// we cant set borderRadius because of a bug, for now i have built a custom IconButton component to use https://github.com/chakra-ui/chakra-ui/pull/4265/commits/d8b9b23e2e6584e04e87bfca21c50165b62b37f3
		primary: {
			backgroundColor: colors.lime[500],
			color: colors.custom1[100],
			_hover: {
				bg: colors.lime[600],
			},
			_active: {
				bg: colors.lime[700],
			},
			_focus: {
				bg: colors.lime[700],
			},
			_disabled: {
				bg: colors.lime[500],
			},
		},
		secondary: {
			border: '2px solid',
			borderColor: colors.leaf[300],
			_hover: {
				borderColor: colors.leaf[200],
			},
			_pressed: {
				backgroundColor: colors.leaf[100],
			},
			_active: {
				backgroundColor: colors.leaf[100],
			},
			_focus: {
				backgroundColor: colors.leaf[100],
			},
			backgroundColor: colors.arctic,
		},
		tertiary: {
			backgroundColor: colors.aquaGreen[100],
		},
		quaternary: {
			backgroundColor: colors.slate[500],
		},
	},
};
