import {createMultiStyleConfigHelpers, MultiStyleConfig} from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['box', 'icon', 'textContentContainer', 'textContentContainerItem']);

export const textAndBullets: MultiStyleConfig = helpers.defineMultiStyleConfig({
	baseStyle: {
		box: {
			p: {base: '2.5rem 0rem 5rem', md: '5rem 0rem 5.5rem'},
		},
		textContentContainerItem: {
			pb: '1rem',
		},
	},
	defaultProps: {},
	sizes: {},
	variants: {},
});
