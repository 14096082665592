/* eslint-disable @typescript-eslint/naming-convention */
import {ComponentStyleConfig} from '@chakra-ui/react';
import {colors} from './colors';

export const Card: ComponentStyleConfig = {
	baseStyle: {
	},
	variants: {
		homeHeroCard: {
			p: {base: '1.063rem 0.5rem 1rem 1rem', md: '3.375rem 2.5rem 3rem 2.438rem'},
			border: '2px',
			borderColor: colors.moss[200],
			borderRadius: '5px',
			backgroundColor: colors.arctic,
			boxShadow: '0 0 15px 0 rgba(8, 146, 95, 0.07)',
			position: 'relative',
			w: {base: '50%', md: '34.688rem'},
			m: {base: 'auto', md: '0'},
			minW: '160px',
			// h: {base: '18.063rem', md: '21.875rem'},
		},
		Post: {
			border: '2px',
			borderColor: colors.moss[200],
			borderRadius: '5px',
			backgroundColor: colors.arctic,
			boxShadow: '0 0 15px 0 rgba(8, 146, 95, 0.07)',
			position: 'relative',
		},
	},
	defaultProps: {
	},
};

export const postCard = {
	border: `2px solid ${colors.moss[200]}`,
	borderColor: colors.moss[200],
	borderRadius: '5px',
	backgroundColor: colors.arctic,
	boxShadow: '0 0 15px 0 rgba(8, 146, 95, 0.07)',
	position: 'relative',
	_hover: {
		borderColor: colors.aquaGreen[400],
	},
};
