/**
 * /* eslint-disable @typescript-eslint/no-unsafe-assignment
 *
 * @format
 */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/naming-convention */

import {color} from '@chakra-ui/react';
import {colors} from './colors';
import {fonts} from './fonts';
import {textStyles} from './textStyles';

const buttonDefaults = {
	display: 'inline-flex',
	alignItems: 'center',
	borderRadius: 'base',
	// fontFamily: fonts.body,
	// fontWeight: 700,
	// lineHeight: '1.5em',
	// letterSpacing: -0.16,
	...textStyles.buttonCenterDark,
	minH: '3rem',
	minW: '6rem',
	verticalAlign: 'middle',
};
export const Link = {
	// 1. Update the base styles
	baseStyle: {color: '#0a1627'},
	sizes: {
		md: {
			h: '3em',
			fontSize: 'md',
			px: '22px',
			py: '12px',
		},
	},
	defaultProps: {
		variant: 'solid',
		size: 'md',
		colorScheme: 'lime',
	},
	// 3.Add visual variants
	variants: {
		icon: {
			w: '3em',
			px: '12px',
			bg: 'lunar.50',
			color: 'abyss.800',
			alignitems: 'center',
			justifyContent: 'center',
			_hover: {
				bg: 'lunar.100',
				textDecoration: 'none',
			},
			_focus: {
				bg: 'lunar.200',
			},
			_active: {
				bg: 'lunar.200',
			},
			_disabled: {
				color: 'granite.300',
			},
			...buttonDefaults,
		},
		solid: props => ({
			color: 'abyss.800',
			bg:
				props.colorScheme === 'gray' ? 'gray.100' : `${props.colorScheme}.500`,
			_hover: {
				textDecoration: 'none',
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.400`,
			},
			_active: {
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.600`,
			},
			_focus: {
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.600`,
			},
			_disabled: {
				color: 'granite.300',
			},
			...buttonDefaults,
		}),
		outline(props) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const {colorScheme: c} = props;
			const borderColor = 'gray.200';
			return {
				border: '2px solid',
				borderColor: c === 'gray' ? borderColor : `${c}.500`,
				color: 'abyss.800',
				bg: 'transparent',
				_hover: {
					bg: 'transparent',
					textDecoration: 'none',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.300`,
				},
				_active: {
					bg: 'transparent',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.600`,
				},
				_disabled: {
					color: 'granite.300',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.300`,
				},
				_focus: {
					borderColor: c === 'gray' ? 'gray.100' : `${c}.600`,
				},
				...buttonDefaults,
			};
		},
		link(props) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const {colorScheme: c} = props;
			return {
				color: c === 'abyss' ? 'abyss.800' : `${c}.500`,
				h: 'auto',
				_hover: {
					textDecoration: 'underline',
					_disabled: {
						textDecoration: 'none',
					},
				},
				_active: {
					color: c === 'abyss' ? 'abyss.800' : `${c}.500`,
				},
				_disabled: {
					color: 'granite.300',
				},
				...buttonDefaults,
				px: '0',
				py: '0',
			};
		},
		textLink(props) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const {colorScheme: c} = props;
			return {
				h: 'auto',
				_hover: {
					textDecoration: 'none',
					color: colors.aquaGreen[300],
					_disabled: {
						textDecoration: 'none',
					},
				},
				_active: {
					color: c === 'abyss' ? 'abyss.800' : `${c}.500`,
				},
				_disabled: {
					color: 'granite.300',
				},
				...buttonDefaults,
				px: '0',
				py: '0',
			};
		},
		primary: {
			bg: 'lime.500',
			_hover: {textDecoration: 'none', bg: 'lime.600'},
			_disabled: {
				bg: 'lime.300',
			},
			_focus: {
				bg: 'lime.600',
			},
			_active: {
				bg: 'lime.600',
			},
			...buttonDefaults,
		},
		secondary: {
			border: '2px solid',
			borderColor: 'lime.500',
			_hover: {textDecoration: 'none', borderColor: 'lime.400'},
			_disabled: {
				borderColor: 'lime.300',
				color: 'granite.300',
			},
			_focus: {
				borderColor: 'lime.600',
			},
			_active: {
				borderColor: 'lime.600',
			},
			...buttonDefaults,
		},
		tertiary: {
			bg: 'lunar.50',
			_hover: {textDecoration: 'none', bg: 'lunar.100'},
			_focus: {
				bg: 'lunar.200',
			},
			_active: {
				bg: 'lunar.200',
			},
			_disabled: {
				color: 'granite.300',
			},
			...buttonDefaults,
		},
		textButton: {
			bg: 'transparent',
			_hover: {textDecoration: 'none', bg: 'transparent'},
			_focus: {
				bg: 'transparent',
			},
			_disabled: {
				color: 'granite.300',
			},
			...buttonDefaults,
		},
		native: {
			cursor: 'pointer',
			color: 'blue',
			px: '0',
			py: '0',
			textDecoration: 'underline',
			_hover: {
				textDecoration: 'underline',
			},
		},
		nativeNoUnderline: {
			cursor: 'pointer',
			color: 'blue',
			px: '0',
			py: '0',
			textDecoration: 'none',
			_hover: {
				textDecoration: 'underline',
			},
		},
		nativeDark: {
			cursor: 'pointer',
			...textStyles.paragraph2LeftItalicDark,
			textDecoration: 'underline',
			p: '0',
			_hover: {
			  textDecoration: 'underline',
			},
		},
	},
};
