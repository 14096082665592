/** @format */

export const fonts = {
	heading: '\'Zilla Slab\', regular',
	body: '\'Zilla Slab\', regular',
	tag: '\'Zilla Slab\', regular',
	button: '\'Outfit\', regular',
	display: '\'Zilla Slab\', regular',
	base: '\'Outfit\', regular',
};
