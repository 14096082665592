/**
 * /* eslint react/prop-types: 1
 *
 * @format
 */

import {ChakraProvider} from '@chakra-ui/react';
import {DefaultSeo} from 'next-seo';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {SWRConfig} from 'swr';
import fetchJson from '../../lib/fetchJson';
import useUser from '../../lib/useUser';
import ExitPreviewPanel from '../components/ExitPreviewPanel';
import {theme} from '../styles/js/theme';
import {ApolloProvider} from '@apollo/client';
import {getApolloClient} from '../lib/api/apollo-client';
import AuthProvider from '../providers/authProvider';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	releaseStage: process.env.VERCEL_ENV,
	enabledReleaseStages: ['production', 'preview'],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// eslint-disable-next-line react/prop-types
export default function App({Component, pageProps}) {
	const router = useRouter();
	// const {user, mutateUser} = useUser();
	// console.log('app user  ', user);
	const handleRouteChange = (url: string) => {
		if (!window?.gtag) {
			return;
		}

		window?.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_CODE, {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			page_path: url,
		});
	};

	useEffect(() => {
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);

	// console.log('App user : ', user);

	const client = getApolloClient();
	return (
		<>
			<ErrorBoundary>
				<ApolloProvider client={client}>
					<ChakraProvider theme={theme}>
						<SWRConfig
							value={{
								fetcher: fetchJson,
								onError(err) {
									console.error(err);
								},
							}}>
							<AuthProvider>
								<GoogleReCaptchaProvider
									reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
									scriptProps={{
										async: false,
										defer: false,
										appendTo: 'head',
										nonce: undefined,
									}}>
									<DefaultSeo
										title='ASSC'
										description="The Association of Scotland's Self-Caterers. Supporting self-catering in Scotland. ASSC Membership. Benefits. Advice. Representation."
									/>
									<ExitPreviewPanel />
									<Component {...pageProps} />
								</GoogleReCaptchaProvider>
							</AuthProvider>
						</SWRConfig>
					</ChakraProvider>
				</ApolloProvider>
			</ErrorBoundary>
		</>
	);
}
